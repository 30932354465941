import { EnHeading } from 'components/atoms/enHeading';

import branding from 'assets/images/services/branding.png';
import businessDesign from 'assets/images/services/business-design.png';
import customerExperienceImprovement from 'assets/images/services/customer-experience-improvement.png';
import employeeExperienceDesign from 'assets/images/services/employee-experience-design.png';
import mvvDesign from 'assets/images/services/mvv-design.png';
import { JaHeading } from 'components/atoms/jaHeading';
import { Text } from 'components/atoms/text';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import FadeUpSection from 'components/organisms/fadeUpSection';
import { InquiryBlock } from 'components/organisms/inquiryBlock';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import { breakpoint, mq } from 'style/variables';
import { ServiceIndexQuery } from 'types/graphql-types';
import SEO from 'util/seo';

import styled from '@emotion/styled';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: ServiceIndexQuery;
}

const TitleSection = styled('div')`
  width: 100%;
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    width: unset;
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;
const Section = styled.section`
  margin-bottom: 180px;

  ${mq.onlysp} {
    margin-bottom: 120px;
  }
`;
const InfoBoxSection = styled.div`
  display: block;
`;
const InfoBox = styled.div`
  position: relative;

  ${mq.onlypc} {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto 1fr;
    align-items: start;
  }

  & + & {
    margin-top: ${sizes.margin['160']};

    ${mq.onlysp} {
      margin-top: 110px;
    }
  }
`;
const InfoBoxCategory = styled(EnHeading)`
  grid-column: 1 / 2;
  ${text(56, 1)}
  margin-bottom: 26px;
  letter-spacing: -0.02em;
  ${mq.onlysp} {
    margin-bottom: 18px;
  }
`;
const InfoBoxHeader = styled(JaHeading)`
  grid-column: 1 / 2;
  ${text(28, 1.5)}
  font-weight: 600;
  letter-spacing: 0.09em;
  ${mq.onlysp} {
    ${text(18, 1.5)}
    font-weight: 600;
  }
`;
const InfoBoxIcon = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  img {
    width: 330px;
    height: auto;
    object-fit: cover;
    display: block;

    ${mq.onlysp} {
      width: 180px;
    }
  }

  ${mq.onlysp} {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-left: 33px;
  }
`;
const InfoBoxContent = styled(Text)`
  grid-column: 1 / 2;
  grid-row: 3;
  margin-top: 50px;
  ${mq.onlypc} {
    ${text(18, 2.12)}
    width: 688px;
  }
  ${mq.onlysp} {
    ${text(16, 1.8)}
    margin-left: 33px;
  }
`;

const infoBoxData: Array<{
  category: string | React.ReactNode;
  header: React.ReactNode;
  icon: string;
  content: string;
}> = [
  {
    category: 'Business Design',
    header: (
      <>
        ユーザー理解を軸に新たな体験を描き出し、
        <br />
        ビジネスの提供価値をデザインする
      </>
    ),
    icon: businessDesign,
    content: `
      既存のチャネルやシステム制約に囚われず、リサーチで得たファクトを元にユーザーが必要とする体験の全体像と具備すべき機能や施策のアイデアを生み出します。ビジネス観点・技術観点でのフィジビリティも踏まえ、サービスやプロダクト開発に向けたロードマップ策定やアーキテクチャ構想支援を行います。
    `,
  },
  {
    category: (
      <>
        Customer Experience
        <br />
        Improvement
      </>
    ),
    header: (
      <>
        既存サービスの利用状況を明らかにし、
        <br />
        理想のあり方に向けた改善を行う
      </>
    ),
    icon: customerExperienceImprovement,
    content: `
      利用データ分析やユーザーリサーチを通して、既存サービスにおける体験とユーザーニーズのギャップを捉え、あるべき体験を再定義します。サービスの認知から利用過程全体において必要な機能を洗い出し、継続改善に向けた開発計画や体制の立案、リリース後の分析や改善支援も提供します。
    `,
  },
  {
    category: 'MVV Design',
    header: (
      <>
        サービスや事業の強みを捉え、
        <br />
        向かうべき方向性を定義する
      </>
    ),
    icon: mvvDesign,
    content: `
      企業やサービスのビジョンを定義し、今後の事業創出や打ち手検討の礎となるコンセプトを策定します。事業推進の判断や従業員の業務遂行の上での指針を得るほか、組織内外のステークホルダーに向け、目指す方向性の浸透を図る施策の企画・開発を行います。
    `,
  },
  {
    category: (
      <>
        Employee
        <br />
        Experience Design
      </>
    ),
    header: (
      <>
        利用者・業務フロー・企業文化を理解し、
        <br />
        よりよい従業員体験を定義する
      </>
    ),
    icon: employeeExperienceDesign,
    content: `
      既存業務や業務に従事する方々への多角的なリサーチを元に、生産性側面だけではなく業務を行う上での心理面も踏まえた理想の従業員体験を定義します。業務システムのUX/UI開発やオフィスなど業務環境のデザイン支援を通して、組織に所属する方々が主体的に成果を生み出せる体験をデザインします。
    `,
  },
  {
    category: 'Branding',
    header: (
      <>
        ブランドのアイデンティティ・価値を形成し、
        <br />
        ユーザーとのつながりを醸成する
      </>
    ),
    icon: branding,
    content: `
      企業や製品、サービスの特性と対象顧客やユーザーを深く理解し、そのブランド価値が正しく伝わるコミュニケーションを包括的にデザインします。ブランドのコンセプト策定からロゴ・ビジュアルアイデンティティ・デザインシステムの構築、コンテンツ設計などを提供します。
    `,
  },
];

const Pages: React.FC<IndexPageProps> = () => {
  let isSp = false;
  if (typeof window !== 'undefined') isSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Services'} />
      <ContentWrapper>
        <TitleSection>
          <EnHeading>Services</EnHeading>
        </TitleSection>
        <Section>
          <FadeUpSection>
            <InfoBoxSection>
              {infoBoxData.map((info, idx) => (
                <InfoBox key={idx}>
                  <InfoBoxCategory>{info.category}</InfoBoxCategory>
                  <InfoBoxHeader>{info.header}</InfoBoxHeader>
                  <InfoBoxContent>{info.content}</InfoBoxContent>
                  <InfoBoxIcon>
                    <img src={info.icon} alt="" />
                  </InfoBoxIcon>
                </InfoBox>
              ))}
            </InfoBoxSection>
          </FadeUpSection>
        </Section>
      </ContentWrapper>

      <InquiryBlock />
    </>
  );
};

export const pageQuery = graphql`
  query ServiceIndex {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Pages;
